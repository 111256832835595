<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap align-items-center justify-content-between mb-3">
                   <div class="d-flex align-items-center justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb p-0 mb-0">
                                <li class="breadcrumb-item">Customers</li>
                                <li class="breadcrumb-item active" aria-current="page">Add Customer</li>
                            </ol>
                        </nav>
                    </div>  
                    <div class=" d-flex justify-content-end">
                        <router-link :to="{ path: '/washsubscription/add/' }" class="btn btn-secondary btn-sm d-flex align-items-center justify-content-between">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2">Back</span>
                        </router-link>
                    </div>                                 
                </div>
            </div>
        </div>
        <div class="row">    
            
            <div v-if="emailAlreadyExist || phoneAlreadyExist" class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                               <h5 class="text-danger">{{ emailAlreadyExist ? 'User Already Exist with this email' : 'User Already Exist with this phone'}}</h5>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                           
                            <div class="col-md-12">                
                                <form class="row g-3 date-icon-set-modal">
                                    <div class="col-md-6 mb-3">
                                        <label for="Text1" class="form-label font-weight-bold text-muted">First Name *</label>
                                        <input type="text" class="form-control" id="Text1" placeholder="First Name" v-model="firstName" @change="handleFirstName" autocomplete="off" > 
                                        <label v-if="invalidFirstName" for="Text1" class="text-uppercase text-danger col-form-label-sm mt-1 ml-1">Invalid First Name</label>
                                    </div>

                                     <div class="col-md-6 mb-3">
                                        <label for="Text1" class="form-label text-muted">Last Name</label>
                                        <input type="text" class="form-control" id="Text1" placeholder="Last Name" v-model="lastName" @change="handleLastName">
                                           <label v-if="invalidLastName" for="Text1" class="text-uppercase text-danger col-form-label-sm mt-1 ml-1">Invalid Last Name</label>
                                    </div>


                                    <div class="col-md-6 mb-3">
                                        <label for="Text5" class="form-label font-weight-bold text-muted">Phone *</label>
                                        <input type="text" class="form-control" id="Text5" placeholder="Enter phone (+9715****)" v-model="phone" @change="handlePhone">
                                           <label v-if="invalidPhone" for="Text1" class="text-uppercase text-danger col-form-label-sm mt-1 ml-1">Invalid Phone</label>
                                    </div>


                                    <div class="col-md-6 mb-3">
                                        <label for="Text4" class="form-label text-muted">Email</label>
                                        <input type="text" class="form-control" id="Text4" placeholder="Enter Email" v-model="email" @change="handleEmail">
                                           <label v-if="invalidEmail" for="Text1" class="text-uppercase text-danger col-form-label-sm mt-1 ml-1">Invalid Email</label>
                                    </div>
                                </form>
                                <div class="d-flex flex-wrap justify-content-between mt-3">
                                    <input class="btn btn-success cust-add btn-sm" id="add-customer" type="button" @click="addCustomer()" value="Add Customer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>


<script>
import { ADD_CUSTOMER } from '../../../graphql/mutation'


export default{
    name: 'CustomerAdd',

    data(){
    return {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      
      errors: [],

      invalidFirstName: false,
      invalidLastName: false,
      invalidPhone: false,
      invalidEmail: false,
      
      phoneAlreadyExist: false,
      emailAlreadyExist: false,
      addCustomerFromOrder: false
    }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if(from.name === 'order.ordernew' ){
            vm.addCustomerFromOrder = true;
        }
      })
    },

    methods: {
    async addCustomer(){       
       
        document.getElementById("add-customer").disabled = true;
        this.$apollo.mutate({
          mutation: ADD_CUSTOMER,
          variables:{
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            email: this.email,
          },
        })
        .then(data => {
                if(data.data.bwsDashboardUserCreate.errors.length == 0){
                    // if(this.addCustomerFromOrder){
                    //     this.$router.push({ path: "/ordernew/" });
                    // }
                    // else{
                    //     var id = data.data.bwsDashboardUserCreate.user.id;
                    //     this.$router.push({ path: "/customerview/" + id });
                    //     document.getElementById("add-customer").disabled = false;
                    // }
                    localStorage.setItem('customer-phone', this.phone);
                    document.getElementById("add-customer").disabled = false;
                    this.$router.go(-1);
                }else{
                    document.getElementById("add-customer").disabled = false;
                    this.errors = data.data.bwsDashboardUserCreate.errors;

                    this.errors.map((e)=>{
                        if(e.message == "Kindly provide first name in English." || this.firstName == ''){
                            return this.invalidFirstName = true
                        }
                        else if(e.message == 'Kindly provide family name in English.'){
                            return this.invalidLastName = true
                        }
                        else if(e.message == "User with this Phone already exists."){
                            return this.phoneAlreadyExist = true
                        }
                        else if(e.message == "Invalid number" || this.phone == ''){
                            return this.invalidPhone = true
                        }
                        else if(e.message == "User with this Email already exists."){
                            return this.emailAlreadyExist = true;
                        }
                        else if(e.message == 'Enter a valid email address.'){
                            return this.invalidEmail = true
                        }
                    })
                }
        })
        .catch(error => {
            console.log((error))
        })
    }, 
    
    


    handleFirstName(){
        this.invalidFirstName = false
        this.emailAlreadyExist = false
        this.phoneAlreadyExist = false
    },

    handleLastName(){
        this.invalidLastName = false
        this.emailAlreadyExist = false
        this.phoneAlreadyExist = false
    },

    handlePhone(){
        this.invalidPhone = false
        this.emailAlreadyExist = false
        this.phoneAlreadyExist = false
    },

    handleEmail(){
        this.invalidEmail = false
        this.emailAlreadyExist = false
        this.phoneAlreadyExist = false
    },
    
  }

}
</script>
<style>
</style>